import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import words from "../components/data/nounGameWords.json";
import flags from "../components/data/flags.json";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
} from "@mui/material";

const GameOverCombination = ({
  score,
  level,
  setGameStage,
  setScore,
  setLevel,
  setDifficulty,
  setClickedOnLanguages,
  setColumnAWrongAnswers,
  setColumnBWrongAnswers,
  winGame,
  correctWords,
  columnAWrongAnswers,
  columnBWrongAnswers,
  setCorrectWords,
  loseText,
  wrongRounds,
  setRounds,
  setLossStreak,
  setRoundNumbers,
  setLanguagesMenu,
  generateLanguagesArray,
  setWrongRounds,
  setLanguageDisplay,
}) => {
  const wrongArrays = mergeArrays(columnAWrongAnswers, columnBWrongAnswers);

  const mergedPairs = mergePairsWithRounds(
    wrongRounds,
    wrongArrays,
    correctWords
  );

  return (
    <>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        sx={{ width: "100%" }}
      >
        {/* Game Messages */}
        <Grid item xs={12} sx={{ textAlign: "center", paddingBottom: 3 }}>
          <Typography variant="h4" color="black" align="center" p={2}>
            {winGame ? "CONGRATULATIONS!" : "GAME OVER"}
          </Typography>
          {winGame && (
            <Typography variant="h5" color="black" align="center" p={2}>
              YOU HAVE WON THE GAME!
            </Typography>
          )}
          <Typography
            variant="h6"
            color="black"
            align="center"
            sx={{ paddingBottom: 3 }}
          >
            {winGame ? "You are a true polyglot!" : loseText}
          </Typography>
          <Typography variant="h6" color="black" align="center">
            {`Final Score: ${score}`}
          </Typography>
          <Typography
            variant="h6"
            color="black"
            align="center"
            sx={{ paddingBottom: 3 }}
          >
            {`Number of Languages: ${level}`}
          </Typography>
        </Grid>

        {/* Table Container */}
        <Grid item xs={12} p={2} sx={{ overflowX: "auto" }}>
          <TableContainer
            component={Paper}
            sx={{
              maxWidth: "100%",
              overflowX: "auto",
              margin: 0,
              padding: 0,
            }}
          >
            <Table border={1}>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#e0e0e0",
                    borderBottom: "5px solid black",
                  }}
                >
                  <TableCell
                    align="center"
                    rowSpan={2}
                    sx={{ fontWeight: "bold" }}
                  >
                    ROUND
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={7}
                    sx={{ fontWeight: "bold" }}
                  >
                    INCORRECT PAIRS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mergedPairs.map((round) => (
                  <React.Fragment key={round.round}>
                    <TableRow>
                      <TableCell
                        align="center"
                        rowSpan={2}
                        sx={{
                          borderBottom: "5px solid black",
                        }}
                      >
                        {round.round}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          backgroundColor: "#FFC0CB",
                          borderRight: "3px solid black",
                        }}
                      >
                        Selected Pair
                      </TableCell>
                      {round.selectedPairs.map((word, index) => (
                        <TableCell
                          align="center"
                          key={`selected-${index}`}
                          sx={{
                            backgroundColor: "#FFC0CB",
                            color: "black",
                            borderRight:
                              (index + 1) % 2
                                ? "1px solid black"
                                : "2px solid black",
                          }}
                        >
                          {flags[word.language] +
                            " " +
                            words[word?.wordIndex][word?.language]}{" "}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          backgroundColor: "lightgreen",
                          color: "black",
                          borderBottom: "5px solid black",
                          borderRight: "3px solid black",
                        }}
                      >
                        Correct Pair(s)
                      </TableCell>
                      {round.correctPairs.map((word, index) => (
                        <TableCell
                          align="center"
                          key={`selected-${index}`}
                          sx={{
                            backgroundColor: "lightgreen",
                            color: "black",
                            borderBottom: "5px solid black",
                            borderRight:
                              (index + 1) % 2
                                ? "1px solid black"
                                : "2px solid black",
                          }}
                        >
                          {flags[word.language] +
                            " " +
                            words[word?.wordIndex][word?.language]}
                        </TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h5" color="black" align="center" p={2}>
            Thank you for playing!
          </Typography>
          <Grid container item justifyContent="space-around">
            <Grid item p={2}>
              <Button
                variant="contained"
                href="/"
                size="large"
                sx={{ marginBottom: 8 }}
              >
                Back to Home Page
              </Button>
            </Grid>
            <Grid item p={2}>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  setScore(0);
                  setDifficulty(0);
                  setLanguagesMenu(generateLanguagesArray());
                  setClickedOnLanguages([]);
                  setColumnAWrongAnswers([]);
                  setColumnBWrongAnswers([]);
                  setCorrectWords([]);
                  setLanguageDisplay(true);
                  setRounds(0);
                  setRoundNumbers(1);
                  setWrongRounds([]);
                  setLossStreak(0);
                  setLevel(2);
                  return setGameStage("game-in-progress");
                }}
                sx={{ marginBottom: 8 }}
              >
                Play Again
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  function mergeArrays(array1, array2) {
    // Check if both arrays are of equal length
    if (array1.length !== array2.length) {
      throw new Error("Both arrays must have the same length");
    }

    const mergedArray = array1.map((item, index) => {
      return [item, array2[index]];
    });

    return mergedArray;
  }

  function mergePairsWithRounds(rounds, selectedPairs, correctPairs) {
    if (
      rounds.length !== selectedPairs.length ||
      rounds.length !== correctPairs.length
    ) {
      throw new Error("All input arrays must have the same length.");
    }

    return rounds.map((round, index) => ({
      round: round,
      selectedPairs: selectedPairs[index],
      correctPairs: correctPairs[index],
    }));
  }
};

export default GameOverCombination;
