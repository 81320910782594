import { useState, useEffect } from "react";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TranslateIcon from "@mui/icons-material/Translate";
import words from "../components/data/nounGameWords.json";
import flags from "../components/data/flags.json";
import GameOver from "../components/GameOver";
import ScoresList from "../components/ScoresList";
import { useCollection } from "../hooks/useCollection";
import { useFirestore } from "../hooks/useFirestore";
import { useAuthContext } from "../hooks/useAuthContext";

const generateLanguagesArray = (clickedOnLanguages = []) => {
  const languageOptions = [
    "English",
    "French",
    "Spanish",
    "Portuguese",
    "German",
    "Russian",
    "Italian",
    "Polish",
    "Arabic",
    "Chinese",
    "Turkish",
    "Korean",
    "Indonesian",
    "Hindi",
    "Japanese",
  ];

  const filteredLanguages = languageOptions.filter(
    (language) => !clickedOnLanguages.includes(language)
  );
  const arrayLength =
    filteredLanguages.length > 5 ? 5 : filteredLanguages.length;
  const generateLanguageIndexesArray = () => {
    const languageIndexesArray = [...new Array(arrayLength)].map(() =>
      Math.floor(Math.random() * filteredLanguages.length)
    );
    const uniqueLanguageIndexes = [...new Set(languageIndexesArray)];
    if (languageIndexesArray.length === uniqueLanguageIndexes.length) {
      return languageIndexesArray;
    }
    return generateLanguageIndexesArray();
  };
  return generateLanguageIndexesArray().map(
    (index) => filteredLanguages[index]
  );
};

const LanguageSummaries = [
  "You will see consecutive sets of three words, all of which are in a different language.",
  "Of the three words, two have the same meaning and one is semantically unrelated. Choose the word whose meaning is different from the other two.",
  "If you choose correctly, you will gain a point and see another set of three words. If you choose incorrectly, the game ends.",
  "After ten rounds with words shown in the same three languages, you advance to the next level and are prompted to add another language. The game will continue until you guess incorrectly.",
  "At the bottom of the page is the list of high scores. See if you can beat any of them.",
];

const Welcome = ({ setGameStage }) => {
  const { documents, error } = useCollection("noun-association-high-scores");
  return (
    <Box p={2}>
      <Grid
        container
        spacing={2}
        p={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h4" color="black" align="center" p={2}>
            Polyglot Challenge: Word Association Game
          </Typography>
        </Grid>
        <Typography variant="h5" color="black" align="left" p={2}>
          Game Summary:{" "}
        </Typography>
        <List>
          {LanguageSummaries.map((summary) => (
            <ListItem key={summary}>
              <TranslateIcon />
              <ListItemText sx={{ marginLeft: 2 }} primary={summary} />
            </ListItem>
          ))}
        </List>
        <Grid item>
          <Button
            variant="contained"
            size="large"
            onClick={() => setGameStage("language-selection")}
            sx={{ marginBottom: 10 }}
          >
            Start Game
          </Button>
        </Grid>
      </Grid>
      <Typography
        variant="h5"
        color="black"
        align="center"
        p={2}
        paddingBottom={3}
        sx={{ textDecoration: "underline" }}
      >
        HIGH SCORES
      </Typography>
      {error && <p>{error}</p>}
      {documents && <ScoresList scores={documents} />}
    </Box>
  );
};

const LanguageSelection = ({
  setGameStage,
  setClickedOnLanguages,
  languagesMenu,
  clickedOnLanguages,
  setWordsArray,
  threeLanguagesWithMatchingWordsArray,
  score,
  level,
}) => {
  const onLanguageSelect = (language) => {
    if (clickedOnLanguages.includes(language)) {
      const filteredClickedOnLanguages = clickedOnLanguages.filter(
        (arrayLanguage) => arrayLanguage !== language
      );
      return setClickedOnLanguages(filteredClickedOnLanguages);
    }

    return setClickedOnLanguages((currentArray) => {
      return [language, ...currentArray];
    });
  };
  const displayThreeWords = () => {
    setGameStage("game-in-progress");
    return setWordsArray(
      threeLanguagesWithMatchingWordsArray(clickedOnLanguages)
    );
  };
  return (
    <Grid
      container
      maxWidth="md"
      direction="column"
      spacing={7}
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h5" color="black" sx={{ marginTop: 7 }}>
          {score < 1
            ? "Please Choose Three Languages:"
            : "Please Choose An Additional Language:"}
        </Typography>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        direction="column"
        wrap="nowrap"
        justifyContent="center"
      >
        <Grid item>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            sx={{ marginBottom: 15 }}
          >
            {languagesMenu.map((language) => (
              <Chip
                key={language}
                variant={
                  clickedOnLanguages.includes(language) ? "filled" : "outlined"
                }
                color="success"
                label={flags[language] + " " + language.toUpperCase()}
                onClick={() => onLanguageSelect(language)}
              />
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        size="large"
        align="center"
        disabled={clickedOnLanguages.length !== level}
        onClick={() => displayThreeWords()}
        sx={{ marginBottom: 10, marginLeft: 7 }}
      >
        Confirm Selection
      </Button>
    </Grid>
  );
};

let timer = null;

const GameInProgress = ({
  clickedOnLanguages,
  score,
  setScore,
  level,
  setLevel,
  chosenWordIndex,
  setChosenWordIndex,
  wordsArray,
  setWordsArray,
  setGameStage,
  winGame,
  setDifficulty,
  threeLanguagesWithMatchingWordsArray,
  setLanguagesMenu,
  clickedWordsIndex,
  setClickedWordsIndex,
  setClickedLanguageIndex,
}) => {
  const { addDocument } = useFirestore("noun-association-high-scores");
  const { user } = useAuthContext();
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    if (!seconds) {
      setClickedWordsIndex(null);
      setClickedLanguageIndex(null);
      user
        ? addDocument({
            score,
            level,
            uid: user.uid,
            displayName: user.displayName,
          })
        : addDocument({
            score,
            level,
            displayName: "---",
          });
      setGameStage("game-over");
    } else {
      timer = setTimeout(() => setSeconds((seconds) => seconds - 1), 1000);
    }
    // eslint-disable-next-line
  }, [seconds, setClickedWordsIndex, setGameStage]);

  const onWordSelect = (word, index) => {
    setClickedWordsIndex(index);
    setClickedLanguageIndex(index);
    return setChosenWordIndex(word);
  };
  const handleSubmit = () => {
    setClickedWordsIndex(null);
    const isAnswerCorrect =
      wordsArray.filter((word) => chosenWordIndex === word.wordIndex).length ===
      1;
    clearTimeout(timer);
    setSeconds(10);
    if (winGame) {
      return setGameStage("game-over");
    }
    if (isAnswerCorrect && score && !(score % 10)) {
      setScore((prevScore) => prevScore + 1);
      setLevel((prevLevel) => prevLevel + 1);
      setDifficulty(150);
      setLanguagesMenu(generateLanguagesArray(clickedOnLanguages));
      return setGameStage("language-selection");
    }
    if (isAnswerCorrect) {
      setScore((prevScore) => prevScore + 1);
      setDifficulty((prevDifficulty) => prevDifficulty + 150);
      return setWordsArray(
        threeLanguagesWithMatchingWordsArray(clickedOnLanguages)
      );
    }
    user
      ? addDocument({
          score,
          level,
          uid: user.uid,
          displayName: user.displayName,
        })
      : addDocument({
          score,
          level,
          displayName: "---",
        });
    return setGameStage("game-over");
  };
  return (
    <>
      <Grid container item justifyContent="space-around" p={2}>
        <Grid item>
          <Typography variant="h6" color="gray">
            {`Score: ${score}`}
          </Typography>
        </Grid>
        <Grid>
          <Typography color="red" variant="h5">
            {seconds}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="gray">
            {`Languages: ${level}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ marginBottom: 5 }}>
        <Typography>{`Which of these nouns does NOT have the same meaning as the other two?`}</Typography>
      </Grid>
      <Grid container alignItems="center" direction="column">
        <Grid>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={5}
            sx={{ marginBottom: 10 }}
          >
            {wordsArray.map((word, index) => (
              <Button
                key={index}
                variant={index === clickedWordsIndex ? "contained" : "outlined"}
                size="medium"
                onClick={() => onWordSelect(word.wordIndex, index)}
              >
                {flags[word.language] +
                  " " +
                  words[word.wordIndex][word.language]}
              </Button>
            ))}
          </Stack>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={clickedWordsIndex === null}
            sx={{ marginBottom: 8 }}
            onClick={handleSubmit}
          >
            Submit Answer
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const NounAssociation = () => {
  const [gameStage, setGameStage] = useState("welcome");
  const [clickedWordsIndex, setClickedWordsIndex] = useState(null);
  const [clickedLanguageIndex, setClickedLanguageIndex] = useState(null);
  const [languagesMenu, setLanguagesMenu] = useState(generateLanguagesArray());
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(3);
  const [difficulty, setDifficulty] = useState(0);
  const winGame = score > 130;

  const [clickedOnLanguages, setClickedOnLanguages] = useState([]);

  const threeLanguagesWithMatchingWordsArray = (clickedOnLanguages) => {
    const shuffleArray = (array) => array.sort(() => 0.5 - Math.random());

    const randomizedLanguages = shuffleArray(clickedOnLanguages);
    const slicedRandomizedLanguages =
      clickedOnLanguages.length > 3
        ? randomizedLanguages.slice(0, 3)
        : randomizedLanguages;
    const generateTwoRandomNumbers = () => {
      const randomlyGeneratedNumberOne =
        Math.floor(Math.random() * 150) + difficulty;
      const randomlyGeneratedNumberTwo =
        Math.floor(Math.random() * 150) + difficulty;

      if (randomlyGeneratedNumberOne === randomlyGeneratedNumberTwo) {
        return generateTwoRandomNumbers();
      }
      return [randomlyGeneratedNumberOne, randomlyGeneratedNumberTwo];
    };
    const wordsIndexesArray = generateTwoRandomNumbers();
    const languagesWithMatchingRandomNumbers = slicedRandomizedLanguages.map(
      (language) => ({
        language,
        wordIndex: shuffleArray(wordsIndexesArray)[0],
      })
    );
    const wordIndexNumberOne = languagesWithMatchingRandomNumbers.filter(
      (language) => language.wordIndex === wordsIndexesArray[0]
    );

    if (wordIndexNumberOne.length === 3 || wordIndexNumberOne.length === 0) {
      return threeLanguagesWithMatchingWordsArray(clickedOnLanguages);
    }
    return languagesWithMatchingRandomNumbers;
  };

  const [wordsArray, setWordsArray] = useState([]);

  const [chosenWordIndex, setChosenWordIndex] = useState("");

  const buildLoseText = () => {
    if (!wordsArray.length) {
      return "";
    }

    const chosenLanguageText = () => {
      if (clickedLanguageIndex === null) {
        return "";
      }
      return `You chose '${
        words[wordsArray[clickedLanguageIndex].wordIndex][
          wordsArray[clickedLanguageIndex].language
        ]
      }'. `;
    };
    const rightAnswer = wordsArray.find(
      (word) =>
        wordsArray.filter((wordObj) => wordObj.wordIndex === word.wordIndex)
          .length === 1
    );

    const englishTextRight = () => {
      if (rightAnswer.language === "English") {
        return true;
      }
      return false;
    };

    const wrongAnswers = wordsArray.filter((word) => word !== rightAnswer);

    const englishTextWrong = () => {
      const wrongAnswerLanguages = wrongAnswers.map(
        (answer) => answer.language
      );
      if (wrongAnswerLanguages.includes("English")) {
        return true;
      }
      return false;
    };

    const gameOverText = () => {
      if (englishTextRight()) {
        return `The different word was ${flags["English"]} '${
          words[rightAnswer.wordIndex]["English"]
        }'. The other two words (${flags[wrongAnswers[0].language]} '${
          words[wrongAnswers[0].wordIndex][wrongAnswers[0].language]
        }' and ${flags[wrongAnswers[1].language]} '${
          words[wrongAnswers[1].wordIndex][wrongAnswers[1].language]
        }') both mean '${words[wrongAnswers[1].wordIndex]["English"]}'.`;
      }

      if (englishTextWrong()) {
        return `The different word was ${flags[rightAnswer.language]} '${
          words[rightAnswer.wordIndex][rightAnswer.language]
        }', which in English means '${
          words[rightAnswer.wordIndex]["English"]
        }'. The other two words (${flags[wrongAnswers[0].language]} '${
          words[wrongAnswers[0].wordIndex][wrongAnswers[0].language]
        }' and ${flags[wrongAnswers[1].language]} '${
          words[wrongAnswers[1].wordIndex][wrongAnswers[1].language]
        }') both have the same meaning.`;
      }

      return `The different word was ${flags[rightAnswer.language]} '${
        words[rightAnswer.wordIndex][rightAnswer.language]
      }', which in English means '${
        words[rightAnswer.wordIndex]["English"]
      }'. The other two words (${flags[wrongAnswers[0].language]} '${
        words[wrongAnswers[0].wordIndex][wrongAnswers[0].language]
      }' and ${flags[wrongAnswers[1].language]} '${
        words[wrongAnswers[1].wordIndex][wrongAnswers[1].language]
      }') both mean '${words[wrongAnswers[1].wordIndex]["English"]}'.`;
    };
    return chosenLanguageText() + gameOverText();
  };

  const gameStages = {
    welcome: <Welcome setGameStage={setGameStage} />,
    "language-selection": (
      <LanguageSelection
        setGameStage={setGameStage}
        setClickedOnLanguages={setClickedOnLanguages}
        languagesMenu={languagesMenu}
        setLanguagesMenu={setLanguagesMenu}
        setWordsArray={setWordsArray}
        clickedOnLanguages={clickedOnLanguages}
        threeLanguagesWithMatchingWordsArray={
          threeLanguagesWithMatchingWordsArray
        }
        score={score}
        level={level}
      />
    ),
    "game-in-progress": (
      <GameInProgress
        setGameStage={setGameStage}
        clickedOnLanguages={clickedOnLanguages}
        setClickedLanguageIndex={setClickedLanguageIndex}
        score={score}
        setScore={setScore}
        level={level}
        setLevel={setLevel}
        chosenWordIndex={chosenWordIndex}
        setChosenWordIndex={setChosenWordIndex}
        setDifficulty={setDifficulty}
        wordsArray={wordsArray}
        setWordsArray={setWordsArray}
        winGame={winGame}
        threeLanguagesWithMatchingWordsArray={
          threeLanguagesWithMatchingWordsArray
        }
        setLanguagesMenu={setLanguagesMenu}
        setClickedWordsIndex={setClickedWordsIndex}
        clickedWordsIndex={clickedWordsIndex}
      />
    ),
    "game-over": (
      <GameOver
        score={score}
        clickedOnLanguages={clickedOnLanguages}
        setGameStage={setGameStage}
        setScore={setScore}
        setLevel={setLevel}
        setDifficulty={setDifficulty}
        winGame={winGame}
        setClickedOnLanguages={setClickedOnLanguages}
        level={level}
        loseText={buildLoseText()}
        setLanguagesMenu={setLanguagesMenu}
        generateLanguagesArray={generateLanguagesArray}
      />
    ),
  };
  return (
    <>
      {" "}
      <Header />
      <Container maxWidth="sm">
        <Box py={10}>{gameStages[gameStage]}</Box>
      </Container>
      <Footer />
    </>
  );
};

export default NounAssociation;
