import { useState, useEffect } from "react";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TranslateIcon from "@mui/icons-material/Translate";
import words from "../components/data/nounGameWords.json";
import flags from "../components/data/flags.json";
import GameOverCombination from "../components/GameOverCombination";
import Snackbar from "@mui/material/Snackbar";
import ScoresList from "../components/ScoresList";
import Alert from "@mui/material/Alert";
import { useFirestore } from "../hooks/useFirestore";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCollection } from "../hooks/useCollection";

const languageSummaries = [
  "In this game you will match nouns in different languages that have the same meaning.",
  "You will choose a language (e.g., Language 1). Words from Language 1 will appear next to words in English, in various orders, in Column A and Column B. Choose the Language 1 word appearing in one column that has the same meaning as the English word in the other column.",
  "After you select one word from Column A and one word from Column B, new words in both languages will appear. Repeat the process and continue selecting word pairs. For every 10 word pairs you select, you will be prompted to choose another language; an additional row of words will then appear. Although over time words in more and more languages will be included in the game, a correct match will always consist of one word from Column A and one word from Column B. NOTE: In rare cases, multiple correct answers are possible (e.g., two words from Column A have the same meaning as one word from Column B, or vice versa).",
  "You gain one point for every correct word pair you choose. You lose one point for every incorrect word pair you choose. Three incorrect selections in a row ends the game. Unless this happens, the game will continue for 10 minutes.",
  "At the bottom of the page is the list of high scores. See if you can beat any of them.",
];

const Welcome = ({ setGameStage, setLanguageDisplay }) => {
  const { documents, error } = useCollection("noun-combination-high-scores");

  const displayLanguages = () => {
    setLanguageDisplay(true);
    setGameStage("game-in-progress");
  };
  return (
    <Box p={2}>
      <Grid
        container
        spacing={2}
        p={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h4" color="black" align="center" p={2}>
            Polyglot Challenge: Word Combination Game
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" color="black" align="center" p={2}>
            Game Summary:{" "}
          </Typography>
          <List>
            {languageSummaries.map((summary) => (
              <ListItem key={summary}>
                <TranslateIcon />
                <ListItemText sx={{ marginLeft: 2 }} primary={summary} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="large"
            onClick={() => displayLanguages()}
            sx={{ marginBottom: 10 }}
          >
            Start Game
          </Button>
        </Grid>
        <Typography
          variant="h5"
          color="black"
          align="center"
          p={2}
          paddingBottom={3}
          sx={{ textDecoration: "underline" }}
        >
          HIGH SCORES
        </Typography>
        {error && <p>{error}</p>}
        {documents && <ScoresList scores={documents} />}
      </Grid>
    </Box>
  );
};

// eslint-disable-next-line
let timer = null;

const GameInProgress = ({
  setWrongRounds,
  setRoundNumbers,
  roundNumbers,
  setGameStage,
  clickedOnLanguages,
  setClickedOnLanguages,
  clickedWordsIndex,
  lossStreak,
  setLossStreak,
  setClickedWordsIndex,
  rounds,
  setRounds,
  setScore,
  score,
  open,
  setOpen,
  setCorrectWords,
  winGame,
  correct,
  setCorrect,
  setColumnAWords,
  columnAWords,
  setLanguageDisplay,
  clickedOnWords,
  setClickedOnWords,
  setColumnASelections,
  columnASelections,
  generateLanguagesArray,
  findMatchingWordIndexes,
  generateWordsArray,
  setLevel,
  setDifficulty,
  level,
  languageDisplay,
  setSelectedLanguage,
  randomLanguageIndex,
  setRandomLanguageIndex,
  setLanguagesMenu,
  languagesMenu,
  useFirestore,
  wordsArray,
  setWordsArray,
  setColumnAWrongAnswers,
  setColumnBWrongAnswers,
}) => {
  const { addDocument } = useFirestore("noun-combination-high-scores");
  const { user } = useAuthContext();

  const [seconds, setSeconds] = useState(600);

  const padTime = (time) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };

  const format = (time) => {
    const minutes = Math.floor(time / 60);

    const seconds = time % 60;

    return `${minutes}:${padTime(seconds)}`;
  };

  useEffect(() => {
    if (!seconds) {
      user
        ? addDocument({
            score,
            level,
            uid: user.uid,
            displayName: user.displayName,
          })
        : addDocument({
            score,
            level,
            displayName: "---",
          });
      setGameStage("game-over");
    } else {
      timer = setTimeout(() => setSeconds((seconds) => seconds - 1), 1000);
    }
    // eslint-disable-next-line
  }, [seconds, setGameStage]);

  useEffect(() => {
    if (lossStreak === 3) {
      user
        ? addDocument({
            score,
            level,
            uid: user.uid,
            displayName: user.displayName,
          })
        : addDocument({
            score,
            level,
            displayName: "---",
          });
      setGameStage("game-over");
    }
    // eslint-disable-next-line
  }, [lossStreak, setGameStage]);

  const handleClose = () => {
    setOpen(false);
  };

  const onLanguageSelect = (language) => {
    setRandomLanguageIndex(
      Math.floor(Math.random() * clickedOnLanguages.length)
    );
    setWordsArray(generateWordsArray(language, level));
    if (clickedOnLanguages.includes(language)) {
      const filteredClickedOnLanguages = clickedOnLanguages.filter(
        (arrayLanguage) => arrayLanguage !== language
      );
      return setClickedOnLanguages(filteredClickedOnLanguages);
    }
    setClickedOnLanguages((currentArray) => [language, ...currentArray]);
    const newClickedOnLanguages = [language, ...clickedOnLanguages];
    setLanguageDisplay(false);
    return setSelectedLanguage(newClickedOnLanguages[randomLanguageIndex]);
  };

  const columnAWordSelected = (word) => {
    setColumnASelections(word);
    setClickedWordsIndex(word.wordIndex);
    setColumnAWords(true);
    setClickedOnWords(word);
  };

  const columnBWordSelected = (word, language) => {
    setRoundNumbers((prevNumber) => prevNumber + 1);
    if (winGame) {
      return setGameStage("game-over");
    }
    setColumnAWords(false);
    setOpen(true);
    setRounds((prevRounds) => prevRounds + 1);
    const result = findMatchingWordIndexes(wordsArray[0], wordsArray[1]);

    if (score && !(rounds % 10)) {
      setLanguageDisplay(true);
      setDifficulty(1);
      setLanguagesMenu(generateLanguagesArray(clickedOnLanguages));
      if (clickedWordsIndex === word.wordIndex) {
        setScore((prevScore) => prevScore + 1);
        setCorrect(true);
      } else {
        setScore((prevScore) => prevScore - 1);
        setCorrect(false);
        setColumnAWrongAnswers((prevArray) => [
          ...prevArray,
          columnASelections,
        ]); // Add new array to state
        setColumnBWrongAnswers((prevArray) => [...prevArray, word]); // Add new array to state
        setCorrectWords((prevArray) => [...prevArray, result]);
        setWrongRounds((prevArray) => [...prevArray, roundNumbers]);
        setLossStreak((prevLossStreak) => prevLossStreak + 1);
        setDifficulty((prevDifficulty) => prevDifficulty - 1);
      }
      return setLevel((prevLevel) => prevLevel + 1);
    }

    if (clickedWordsIndex === word.wordIndex) {
      setLossStreak(0);
      setScore((prevScore) => prevScore + 1);
      setCorrect(true);
      setWordsArray(generateWordsArray(language, level));
      return setDifficulty((prevDifficulty) => prevDifficulty + 1);
    }
    setCorrectWords((prevArray) => [...prevArray, result]);
    setWrongRounds((prevArray) => [...prevArray, roundNumbers]);
    setColumnAWrongAnswers((prevArray) => [...prevArray, columnASelections]); // Add new array to state
    setColumnBWrongAnswers((prevArray) => [...prevArray, word]); // Add new array to state
    setCorrect(false);
    setLossStreak((prevLossStreak) => prevLossStreak + 1);
    setDifficulty((prevDifficulty) => prevDifficulty - 1);
    setScore((prevScore) => prevScore - 1);
    return setWordsArray(generateWordsArray(language, level));
  };
  return (
    <Grid>
      <Grid container direction="column" spacing={7} marginBottom={10}>
        <Grid container item justifyContent="space-around">
          <Grid item>
            <Typography variant="h6" color="gray">
              {`Score: ${score}`}
            </Typography>
          </Grid>
          <Typography color="red" variant="h5">
            {format(seconds)}
          </Typography>
          <Grid item>
            <Typography variant="h6" color="gray">
              {`Languages: ${level}`}
            </Typography>
          </Grid>
        </Grid>
        {languageDisplay && (
          <Grid item>
            <Typography
              variant="h5"
              color="black"
              align="center"
              sx={{ margin: 1 }}
            >
              Please Choose {score < 1 ? "A" : "Another"} Language
            </Typography>
          </Grid>
        )}
        <Grid
          container
          item
          spacing={languagesMenu.includes("Portuguese") ? 1.25 : 2}
          alignItems="center"
          justifyContent="center"
          direction={{ xs: "column", sm: "row" }}
        >
          {languageDisplay &&
            languagesMenu.map((language) => (
              <Grid item key={language}>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => onLanguageSelect(language)}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {flags[language] + " " + language}
                </Button>
              </Grid>
            ))}
        </Grid>
        {!languageDisplay && (
          <Grid item>
            <Typography
              variant="h6"
              color="black"
              align="center"
              sx={{ margin: 1 }}
            >
              Which two words below have the same meaning?
            </Typography>
          </Grid>
        )}
      </Grid>
      {rounds !== 0 && (
        <Snackbar
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }} // Use a supported value
          sx={{
            top: "50%", // Move to the center of the screen
            transform: "translateY(-50%)", // Center vertically
            "& .MuiSnackbarContent-root": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          {correct ? (
            <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
              Correct!{" "}
            </Alert>
          ) : (
            <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
              Incorrect!{" "}
            </Alert>
          )}
        </Snackbar>
      )}
      <Grid container direction="column" spacing={2}>
        <Grid container item justifyContent="space-around">
          {!languageDisplay && (
            <Grid item>
              <Typography variant="h6" color="gray">
                COLUMN A
              </Typography>
            </Grid>
          )}
          {!languageDisplay && (
            <Grid item>
              <Typography variant="h6" color="gray">
                COLUMN B
              </Typography>
            </Grid>
          )}
        </Grid>
        {!languageDisplay && !!wordsArray.length && (
          <Grid container item justifyContent="space-around">
            <Grid item>
              {wordsArray[0].map((word, index) => (
                <Grid key={index} item p={1}>
                  <Chip
                    variant={clickedOnWords === word ? "filled" : "outlined"}
                    color="success"
                    label={
                      flags[word?.language] +
                      " " +
                      words[word?.wordIndex][word?.language]
                    }
                    onClick={() => columnAWordSelected(word)}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item>
              {" "}
              {wordsArray[1].map((word, index) => (
                <Grid key={index} item p={1}>
                  <Chip
                    disabled={!columnAWords}
                    variant={"outlined"}
                    color="success"
                    label={
                      flags[word?.language] +
                      " " +
                      words[word?.wordIndex][word?.language]
                    }
                    onClick={() => columnBWordSelected(word, word.language)}
                  />
                </Grid>
              ))}{" "}
            </Grid>
          </Grid>
        )}
        <Grid container item alignItems="center" justifyContent="center"></Grid>
      </Grid>
    </Grid>
  );
};

const NounCombination = () => {
  const [gameStage, setGameStage] = useState("welcome");
  const [clickedWordsIndex, setClickedWordsIndex] = useState(0);
  const [lossStreak, setLossStreak] = useState(0);
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(2);
  const [difficulty, setDifficulty] = useState(0);
  const [rounds, setRounds] = useState(0);
  const winGame = score > 99;
  const buildLoseText = () => {
    return lossStreak === 3
      ? `Your last three answers were incorrect.`
      : "Time's up.";
  };

  const [randomLanguageIndex, setRandomLanguageIndex] = useState(0);

  const generateLanguagesArray = (clickedOnLanguages = []) => {
    const languageOptions = [
      "French",
      "Spanish",
      "Portuguese",
      "German",
      "Russian",
      "Italian",
      "Polish",
      "Arabic",
      "Chinese",
      "Turkish",
      "Korean",
      "Indonesian",
      "Hindi",
      "Japanese",
    ];

    const filteredLanguages = languageOptions.filter(
      (language) => !clickedOnLanguages.includes(language)
    );

    const arrayLength =
      filteredLanguages.length > 6 ? 6 : filteredLanguages.length;
    const generateLanguageIndexesArray = () => {
      const languageIndexesArray = [...new Array(arrayLength)].map(() =>
        Math.floor(Math.random() * filteredLanguages.length)
      );
      const uniqueLanguageIndexes = [...new Set(languageIndexesArray)];
      if (languageIndexesArray.length === uniqueLanguageIndexes.length) {
        return languageIndexesArray;
      }
      return generateLanguageIndexesArray();
    };
    return generateLanguageIndexesArray().map(
      (index) => filteredLanguages[index]
    );
  };

  const generateWordsArray = (language, level) => {
    const difficultyAbs = Math.abs(difficulty);

    const createLanguageArray = languageDisplay
      ? ["English", `${language}`, ...clickedOnLanguages]
      : ["English", ...clickedOnLanguages];

    const shuffleArray = (array) => array.sort(() => 0.5 - Math.random());
    const numbersOne = Array(150)
      .fill()
      .map((_, index) => index);
    numbersOne.sort(() => Math.random() - 0.5);

    const numberMultiplierOne = numbersOne.map((number) => {
      if (score) {
        return number + 150 * difficultyAbs;
      }
      return number;
    });

    const columnAArray = numberMultiplierOne.slice(0, level);

    const numbersTwo = Array(150)
      .fill()
      .map((_, index) => index);
    numbersTwo.sort(() => Math.random() - 0.5);

    const numberMultiplierTwo = numbersTwo.map((number) => {
      if (score) {
        return number + 150 * difficultyAbs;
      }
      return number;
    });

    numberMultiplierTwo.sort(() => Math.random() - 0.5);
    const columnBArray = numberMultiplierTwo.slice(0, level);
    function getOneRandomNumber(array) {
      const randomIndex = Math.floor(Math.random() * array.length);
      return array[randomIndex];
    }

    const oneRandomNumber = getOneRandomNumber(columnAArray);

    function substituteElement(array, elementToReplace, newElement) {
      const index = array.indexOf(elementToReplace);
      if (index !== -1) {
        array[index] = newElement;
      }
      return array;
    }

    const columnBArrayNew = substituteElement(
      columnBArray,
      columnBArray[0],
      oneRandomNumber
    );
    const columnBArrayFinal = shuffleArray(columnBArrayNew);

    const columnAWordsObject = columnAArray.reduce(function (
      result,
      field,
      index
    ) {
      result[createLanguageArray[index]] = field;
      return result;
    },
    {});

    const columnBWordsObject = columnBArrayFinal.reduce(function (
      result,
      field,
      index
    ) {
      result[createLanguageArray[index]] = field;
      return result;
    },
    {});

    const array1 = Object.entries(columnAWordsObject);
    const array2 = Object.entries(columnBWordsObject);

    const sumWithInitial = array1.reduce((accumulator, [language1, value1]) => {
      const isDuplicate = !!array2.find(
        ([language2, value2]) => language1 === language2 && value1 === value2
      );
      return accumulator && !isDuplicate;
    }, true);

    if (!sumWithInitial) {
      return generateWordsArray(language, level);
    }

    if (
      Object.values(columnAWordsObject)[0] ===
        Object.values(columnBWordsObject)[0] ||
      Object.values(columnAWordsObject)[1] ===
        Object.values(columnBWordsObject)[1]
    ) {
      return generateWordsArray(language, level);
    }

    const columnAWordsArray = [];

    for (let key in columnAWordsObject) {
      if (columnAWordsObject.hasOwnProperty(key)) {
        const newObj = {};
        newObj[key] = columnAWordsObject[key];
        columnAWordsArray.push(newObj);
      }
    }

    const columnBWordsArray = [];

    for (let key in columnAWordsObject) {
      if (columnBWordsObject.hasOwnProperty(key)) {
        const newObj = {};
        newObj[key] = columnBWordsObject[key];
        columnBWordsArray.push(newObj);
      }
    }

    function transformData(data) {
      const transformedData = [];

      for (const item of data) {
        const innerObject = {};

        for (const [key, value] of Object.entries(item)) {
          innerObject.language = key;
          innerObject.wordIndex = value;
        }

        transformedData.push(innerObject);
      }

      return transformedData;
    }

    const transformedData1 = transformData(columnAWordsArray);
    const transformedData2 = transformData(columnBWordsArray);
    const separatedArrays = [transformedData1, transformedData2];

    return separatedArrays;
  };

  function findMatchingWordIndexes(array1, array2) {
    let matches = [];

    array1.forEach((obj1) => {
      array2.forEach((obj2) => {
        if (obj1.wordIndex === obj2.wordIndex) {
          matches.push(obj1);
          matches.push(obj2);
        }
      });
    });

    return matches;
  }

  const [clickedOnLanguages, setClickedOnLanguages] = useState([]);
  const [clickedOnWords, setClickedOnWords] = useState("");
  const [open, setOpen] = useState(false);
  const [correct, setCorrect] = useState(false);
  const [columnAWords, setColumnAWords] = useState(false);
  const [languageDisplay, setLanguageDisplay] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [languagesMenu, setLanguagesMenu] = useState(
    generateLanguagesArray(clickedOnLanguages)
  );

  const [columnASelections, setColumnASelections] = useState([]);

  const [columnAWrongAnswers, setColumnAWrongAnswers] = useState([]);
  const [columnBWrongAnswers, setColumnBWrongAnswers] = useState([]);

  const [roundNumbers, setRoundNumbers] = useState(1);
  const [wrongRounds, setWrongRounds] = useState([]);

  const [chosenWordIndex, setChosenWordIndex] = useState("");
  const [twoChosenWords, setTwoChosenWords] = useState(false);

  const [wordsArray, setWordsArray] = useState([]); // Initialize state with an empty array
  const [correctWords, setCorrectWords] = useState([]);

  const gameStages = {
    welcome: (
      <Welcome
        setGameStage={setGameStage}
        setLanguageDisplay={setLanguageDisplay}
      />
    ),
    "game-in-progress": (
      <GameInProgress
        columnAWrongAnswers={columnAWrongAnswers}
        columnBWrongAnswers={columnBWrongAnswers}
        roundNumbers={roundNumbers}
        setRoundNumbers={setRoundNumbers}
        wrongRounds={wrongRounds}
        setWrongRounds={setWrongRounds}
        setLanguagesMenu={setLanguagesMenu}
        setGameStage={setGameStage}
        languagesMenu={languagesMenu}
        score={score}
        setScore={setScore}
        difficulty={difficulty}
        setRounds={setRounds}
        rounds={rounds}
        setCorrectWords={setCorrectWords}
        correctWords={correctWords}
        useFirestore={useFirestore}
        clickedOnWords={clickedOnWords}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        twoChosenWords={twoChosenWords}
        setTwoChosenWords={setTwoChosenWords}
        languageDisplay={languageDisplay}
        setLanguageDisplay={setLanguageDisplay}
        clickedWordsIndex={clickedWordsIndex}
        setClickedOnWords={setClickedOnWords}
        setClickedWordsIndex={setClickedWordsIndex}
        level={level}
        setLevel={setLevel}
        lossStreak={lossStreak}
        setLossStreak={setLossStreak}
        open={open}
        setOpen={setOpen}
        setCorrect={setCorrect}
        setColumnAWords={setColumnAWords}
        columnAWords={columnAWords}
        correct={correct}
        clickedOnLanguages={clickedOnLanguages}
        setClickedOnLanguages={setClickedOnLanguages}
        chosenWordIndex={chosenWordIndex}
        setChosenWordIndex={setChosenWordIndex}
        setDifficulty={setDifficulty}
        wordsArray={wordsArray}
        setWordsArray={setWordsArray}
        setColumnASelections={setColumnASelections}
        setColumnAWrongAnswers={setColumnAWrongAnswers}
        setColumnBWrongAnswers={setColumnBWrongAnswers}
        winGame={winGame}
        setRandomLanguageIndex={setRandomLanguageIndex}
        randomLanguageIndex={randomLanguageIndex}
        generateLanguagesArray={generateLanguagesArray}
        findMatchingWordIndexes={findMatchingWordIndexes}
        generateWordsArray={generateWordsArray}
        columnASelections={columnASelections}
      />
    ),
    "game-over": (
      <GameOverCombination
        setCorrectWords={setCorrectWords}
        setColumnAWrongAnswers={setColumnAWrongAnswers}
        setColumnBWrongAnswers={setColumnBWrongAnswers}
        columnAWrongAnswers={columnAWrongAnswers}
        columnBWrongAnswers={columnBWrongAnswers}
        setRoundNumbers={setRoundNumbers}
        correctWords={correctWords}
        score={score}
        clickedOnLanguages={clickedOnLanguages}
        setGameStage={setGameStage}
        setScore={setScore}
        setLevel={setLevel}
        loseText={buildLoseText()}
        setLossStreak={setLossStreak}
        setDifficulty={setDifficulty}
        winGame={winGame}
        setCorrect={setCorrect}
        setRounds={setRounds}
        columnASelections={columnASelections}
        setClickedOnLanguages={setClickedOnLanguages}
        level={level}
        wrongRounds={wrongRounds}
        setWrongRounds={setWrongRounds}
        isNounCombination={true}
        setLanguageDisplay={setLanguageDisplay}
        setLanguagesMenu={setLanguagesMenu}
        generateLanguagesArray={generateLanguagesArray}
      />
    ),
  };
  return (
    <>
      {" "}
      <Header />
      <Container>
        <Box py={10}>{gameStages[gameStage]}</Box>
      </Container>
      <Footer />
    </>
  );
};

export default NounCombination;
