import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import { Link, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";

const preventDefault = (event) => event.preventDefault();
const footerLinks = [
  { label: "Home", href: "/" },
  { label: "About the Site", href: "/about" },
  { label: "Contact", href: "/contact" },
];

export default function Footer() {
  const location = useLocation();
  const filteredFooterLinks = footerLinks.filter(
    (link) => link.href !== location.pathname
  );
  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ bgcolor: "black" }} p={6}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography variant="h6" color="white">
              © Copyright 2025 GlosKosmos
            </Typography>
          </Grid>
          <Grid item>
            <Box
              sx={{
                typography: "body1",
                "& > :not(style) + :not(style)": {
                  ml: 2,
                },
              }}
              onClick={preventDefault}
              marginTop={1}
            >
              {filteredFooterLinks.map((link, index) => (
                <Link key={index} style={{ color: "#FFF" }} to={link.href}>
                  {link.label}
                </Link>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
